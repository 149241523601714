/**
 * @file auth.sagas.js
 * @description This file contains all the saga functions related to user authentication.
 * It handles login, registration, logout, password reset, and other authentication-related
 * processes using Redux Saga.
 *
 * @requires redux-saga/effects
 * @requires ../../helpers/Firebase
 * @requires ../../helpers/Authservice
 * @requires ../../Utils/Common
 */

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth } from "../../helpers/Firebase";
import Authservice from "../../helpers/Authservice";
import { setUserSession } from "../../Utils/Common";

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";

/**
 * Watches for LOGIN_USER action and calls loginWithEmailPassword saga
 * @generator
 * @yields {Object} A takeEvery effect for LOGIN_USER actions
 */
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

/**
 * Calls the login method of Authservice
 * @async
 * @param {string} username - The user's username
 * @param {string} password - The user's password
 * @returns {Promise<Object>} The response from the login attempt
 * @throws {Error} If the login attempt fails
 */
const loginWithEmailPasswordAsync = async (username, password) =>
  await Authservice.login(username, password)
    .then((authUser) => authUser)
    .catch((error) => error);

/**
 * Handles the login process
 * @generator
 * @param {Object} action - The LOGIN_USER action
 * @param {Object} action.payload - The payload of the action
 * @param {Object} action.payload.user - The user's login credentials
 * @param {string} action.payload.user.username - The user's username
 * @param {string} action.payload.user.password - The user's password
 * @param {Object} action.payload.history - The history object for navigation
 * @yields {Object} A redux action to be dispatched
 */
function* loginWithEmailPassword({ payload }) {
  const { username, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(
      loginWithEmailPasswordAsync,
      username,
      password
    );
    console.log(loginUser);
    if (!loginUser.message) {
      // Store user data in localStorage
      localStorage.setItem("user_id", loginUser.user.userId);
      localStorage.setItem("client_id", loginUser.user.client_id);
      localStorage.setItem("isAdmin", loginUser.user.isAdmin);
      localStorage.setItem("isClientAdmin", loginUser.user.isClientAdmin);
      localStorage.setItem(
        "is_password_change",
        loginUser.user.is_password_change
      );
      localStorage.setItem(
        "password_change_required",
        loginUser.user.password_change_required
      );

      // Dispatch success action
      yield put(loginUserSuccess(loginUser.user));

      // Navigate based on user role
      history.push("/app/dashboard");
    } else {
      console.log(loginUser.message);
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

/**
 * Watches for REGISTER_USER action and calls registerWithEmailPassword saga
 * @generator
 * @yields {Object} A takeEvery effect for REGISTER_USER actions
 */
export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

/**
 * Registers a new user with email and password
 * @async
 * @param {string} email - The user's email
 * @param {string} password - The user's password
 * @returns {Promise<Object>} The response from the registration attempt
 * @throws {Error} If the registration attempt fails
 */
const registerWithEmailPasswordAsync = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

/**
 * Handles the user registration process
 * @generator
 * @param {Object} action - The REGISTER_USER action
 * @param {Object} action.payload - The payload of the action
 * @param {Object} action.payload.user - The user's registration data
 * @param {string} action.payload.user.email - The user's email
 * @param {string} action.payload.user.password - The user's password
 * @param {Object} action.payload.history - The history object for navigation
 * @yields {Object} A redux action to be dispatched
 */
function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      localStorage.setItem("user_id", registerUser.user.uid);
      yield put(registerUserSuccess(registerUser));
      history.push("/");
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

/**
 * Watches for LOGOUT_USER action and calls logout saga
 * @generator
 * @yields {Object} A takeEvery effect for LOGOUT_USER actions
 */
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

/**
 * Logs out the user
 * @async
 * @param {Object} history - The history object for navigation
 * @returns {Promise<Object>} The response from the logout attempt
 * @throws {Error} If the logout attempt fails
 */
const logoutAsync = async (history) => {
  await Authservice.logout()
    .then((authUser) => authUser)
    .catch((error) => error);
  history.push("/");
};

/**
 * Handles the user logout process
 * @generator
 * @param {Object} action - The LOGOUT_USER action
 * @param {Object} action.payload - The payload of the action
 * @param {Object} action.payload.history - The history object for navigation
 * @yields {Object} A call effect to the logoutAsync function
 */
function* logout({ payload }) {
  const { history } = payload;
  try {
    console.log("logout called");
    yield call(logoutAsync, history);
  } catch (error) {
    console.error("Logout error:", error);
  }
}

/**
 * Watches for FORGOT_PASSWORD action and calls forgotPassword saga
 * @generator
 * @yields {Object} A takeEvery effect for FORGOT_PASSWORD actions
 */
export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

/**
 * Sends a password reset email
 * @async
 * @param {string} email - The user's email
 * @returns {Promise<Object>} The response from the password reset email attempt
 * @throws {Error} If the password reset email attempt fails
 */
const forgotPasswordAsync = async (email) => {
  return await Authservice.sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => {
      console.error("Error in forgotPasswordAsync:", error);
      return error;
    });
};

/**
 * Handles the forgot password process
 * @generator
 * @param {Object} action - The FORGOT_PASSWORD action
 * @param {Object} action.payload - The payload of the action
 * @param {Object} action.payload.forgotUserMail - The user's email for password reset
 * @param {string} action.payload.forgotUserMail.emailOrUsername - The user's email or username
 * @yields {Object} A redux action to be dispatched
 */
function* forgotPassword({ payload }) {
  const { emailOrUsername } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(
      forgotPasswordAsync,
      emailOrUsername
    );

    if (forgotPasswordStatus && forgotPasswordStatus.data) {
      if (forgotPasswordStatus.data.status === true) {
        yield put(forgotPasswordSuccess("success"));
      } else {
        yield put(forgotPasswordError(forgotPasswordStatus.data.message));
      }
    } else {
      yield put(forgotPasswordError("Invalid response format from server"));
    }
  } catch (error) {
    console.error("Error in forgotPassword saga:", error.message);
    yield put(
      forgotPasswordError(error.response?.data?.message || error.message)
    );
  }
}

/**
 * Watches for RESET_PASSWORD action and calls resetPassword saga
 * @generator
 * @yields {Object} A takeEvery effect for RESET_PASSWORD actions
 */
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

/**
 * Resets the user's password
 * @async
 * @param {string} resetPasswordCode - The reset password code
 * @param {string} newPassword - The new password
 * @returns {Promise<Object>} The response from the password reset attempt
 * @throws {Error} If the password reset attempt fails
 */
const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await Authservice.sendPasswordResetEmailusers(
    resetPasswordCode,
    newPassword
  )
    .then((user) => user)
    .catch((error) => error);
};

/**
 * Handles the password reset process
 * @generator
 * @param {Object} action - The RESET_PASSWORD action
 * @param {Object} action.payload - The payload of the action
 * @param {string} action.payload.newPassword - The new password
 * @param {string} action.payload.resetPasswordCode - The reset password code
 * @yields {Object} A redux action to be dispatched
 */
function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );

    if (resetPasswordStatus.data.status === true) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

/**
 * Root saga that forks all watcher sagas
 * @generator
 * @yields {Object} Fork effects for all watcher sagas
 */
export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
