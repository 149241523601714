import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import addclient from './client/reducer';
import adduser from './client/reducer';
import recordings from './recordings/reducer';
import data from './graph/reducer';


//import data from '../reducers/data';
import files from '../reducers/files';
import params from '../reducers/params';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  addclient,
  adduser,
  recordings,
  data,
  files,
  params,

});

export default reducers;