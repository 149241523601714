import React, { useState } from "react";
import axios from "axios";
import { setUserSession } from "../Utils/Common";
import { Route, Redirect } from "react-router-dom";

const API_URL = process.env.REACT_APP_SERVER_URL

class Authservice {
  login(username, password) {
    return axios
      .post(API_URL + "users/signin", { username, password })
      .then((response) => {
        setUserSession(response.data.token, response.data.user);
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        //return "hello world";
        return response.data;
      });
  }

  logout() {
    console.log(sessionStorage.getItem("token"));
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    window.location.href = "/";
  }

  addclient(item) {
    let title = item.title,
      username = item.username,
      status = item.status,
      password = item.password,
      middlename = item.middlename,
      lastname = item.lastname,
      height = item.height,
      healthinfo = item.healthinfo,
      gender = item.gender,
      firstname = item.firstname,
      facilitysite = item.facilitysite,
      email = item.email,
      dob = item.dob,
      clientref = item.clientref,
      bodymass = item.bodymass;

    return axios
      .post(API_URL + "add-clients", {
        title,
        status,
        username,
        password,
        middlename,
        lastname,
        height,
        healthinfo,
        gender,
        firstname,
        facilitysite,
        email,
        dob,
        clientref,
        bodymass,
      })
      .then((response) => {
        console.log(response);
        return response;
      });
  }

  getClientList() {
    return axios.get(API_URL + "all-clients").then((response) => {
      return response;
    });
  }

  getClientListbyId(id) {
    return axios
      .post(API_URL + "get-client-list-by-id", { id })
      .then((response) => {
        return response;
      });
  }

  deleteClientItem(id) {
    return axios.post(API_URL + "delete-clients", { id }).then((response) => {
      return response;
    });
  }

  deleteUserItem(id) {
    return axios.post(API_URL + "delete-users", { id }).then((response) => {
      return response;
    });
  }

  adduser(item) {
    let client_id = parseInt(item.companyid),
      bodymass_kg = parseInt(item.bodymass),
      client_ref = item.clientref,
      dob = item.dob,
      email = item.email,
      facility_site = item.facilitysite,
      first_name = item.firstname,
      last_name = item.lastname,
      gender = item.gender,
      health_info_json = item.healthinfo,
      height_cm = parseInt(item.height),
      middle_initials = item.middlename,
      password = item.password,
      active = item.status,
      username = item.username,
      client_admin = 1;

    return axios
      .post(API_URL + "add-users", {
        client_id,
        active,
        client_admin,
        username,
        password,
        first_name,
        last_name,
        middle_initials,
        email,
        dob,
        gender,
        height_cm,
        bodymass_kg,
        facility_site,
        health_info_json,
        client_ref,
      })
      .then((response) => {
        return response;
      });
  }

  getUserList() {
    return axios.get(API_URL + "all-users").then((response) => {
      return response;
    });
  }

  getUserListbyCompany(payload) {
    return axios
      .post(API_URL + "all-users-by-cpmpany", { payload })
      .then((response) => {
        return response;
      });
  }

  edit_company_name(id, name) {
    return axios
      .post(API_URL + "edit-company-records", { id, name })
      .then((response) => {
        return response;
      });
  }

  edit_company_details(item) {
    let clientid = parseInt(item.clientid),
      bodymass_kg = parseInt(item.bodymass_kg),
      clientinfo = item.clientinfo,
      email = item.email,
      facility_site = item.facility_site,
      first_name = item.first_name,
      last_name = item.last_name,
      healthinfo = item.healthinfo,
      height_cm = parseInt(item.height_cm),
      middle_initials = item.middle_initials,
      password = item.password,
      status = item.status,
      username = item.username,
      id = item.id;

    return axios
      .post(API_URL + "edit-company-details", {
        id,
        first_name,
        last_name,
        middle_initials,
        email,
        height_cm,
        bodymass_kg,
        facility_site,
        healthinfo,
        clientinfo,
        status,
        clientid,
        password,
      })
      .then((response) => {
        return response;
      });
  }

  sendPasswordResetEmail(emailOrUsername) {
    return axios
      .post(API_URL + "forgotpassword", { email: emailOrUsername })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error in sendPasswordResetEmail:", error.message);
        throw error;
      });
  }

  sendPasswordResetEmailusers(code, password) {
    return axios
      .post(API_URL + "reset-password-users", { code, password })
      .then((response) => {
        return response;
      });
  }
}

export default new Authservice();